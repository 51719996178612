import { InputAdornment, TextField, Typography } from '@material-ui/core';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import PersistenceForm from '../../../../Modals/PersistenceModal/PersistenceForm';
import useStyles from '../../../../Modals/PersistenceModal/PersistenceForm/styles';
import useAddStatistics from './hook';
import { schema } from './schema';
import { FIELDS } from './fields';

interface StatisticCreationFormProps {
  onClose: () => void;
}

export interface StatisticCreationFormInterface {
  cholesterol: string;
  diastolicBp: string;
  glycemia: string;
  hdl: string;
  ldl: string;
  systolicBp: string;
  timeElapsedAfterMeal: string;
  triglycerides: string;
  waistSize: string;
  weight: string;
}

const DEFAULT_FORM = {
  cholesterol: '',
  diastolicBp: '',
  glycemia: '',
  hdl: '',
  ldl: '',
  systolicBp: '',
  timeElapsedAfterMeal: '',
  triglycerides: '',
  waistSize: '',
  weight: '',
};

const StatisticCreationForm: FunctionComponent<StatisticCreationFormProps> = ({ onClose }) => {
  const classes = useStyles();

  const { isSuccess, error, add, isLoading } = useAddStatistics();

  const onSubmit = (data: StatisticCreationFormInterface) => {
    const cleanedData = Object.entries(data).reduce((acc, [key, value]) => {
      if (value === '') {
        return acc;
      }
      return {
        ...acc,
        [key]: value,
      };
    }, {} as StatisticCreationFormInterface);
    add(cleanedData);
  };

  return (
    <PersistenceForm
      error={error}
      initialValues={DEFAULT_FORM}
      isUpdateMode={false}
      isCreating={isLoading}
      isSubmittingSuccessfully={isSuccess}
      onClose={onClose}
      onSubmit={onSubmit}
      validationSchema={schema}
      formContent={(props: FormikProps<StatisticCreationFormInterface>) => (
        <>
          {FIELDS.map(({ key, label, unit }) => (
            <div key={key} className={classes.fieldRow}>
              <div className={classes.fieldLabelContainer}>
                <Typography variant={'body2'}>{label}: </Typography>
              </div>
              <div className={classes.inputContainer}>
                <Field
                  value={props.values[key]}
                  margin={'dense'}
                  variant={'outlined'}
                  color={'primary'}
                  id="value"
                  name="value"
                  placeholder=""
                  type="number"
                  error={!!props.errors[key]}
                  helperText={props.errors[key]}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">{unit.label}</InputAdornment>,
                  }}
                  onChange={(
                    event: React.ChangeEvent<{ name: string | undefined; value: number }>,
                  ) => {
                    props.setFieldValue(key, event.target.value, true);
                  }}
                  onWheel={(e: React.WheelEvent<HTMLInputElement>) => {
                    (e.target as HTMLInputElement).blur();
                  }}
                  onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                      e.preventDefault();
                    }
                  }}
                  component={TextField}
                />
              </div>
            </div>
          ))}
        </>
      )}
    />
  );
};

export default StatisticCreationForm;
